// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: '8f8cf7cf-1d57-41e4-b0e3-5337b8ff4010',
      scopes: ['8f8cf7cf-1d57-41e4-b0e3-5337b8ff4010']
    }
  },
  crmConnectorConfig: {
    scopes: ['https://erbeappstest.onmicrosoft.com/crmconnector/UnifiedLoginRights.Read'],
    uri: 'https://crmconnector3t3nrcjmcbf34.azurewebsites.net'
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'b2c_1a_unifiedlogin_signupsignin'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://erbeappstest.b2clogin.com/tfp/erbeappstest.onmicrosoft.com/b2c_1a_unifiedlogin_signupsignin'
      }
    },
    authorityDomain: 'erbeappstest.b2clogin.com'
  },
  termsOfUse: 'https://en.erbe-med.com/fileadmin/user_upload/documents/PDF/Terms_of_use_EN.pdf',
  privacyPolicy: 'https://en.erbe-med.com/en-en/privacy-policy/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
