import { Injectable } from '@angular/core'
import { Adapter } from 'src/app/core/interfaces/adapter.interface';

export class Application {
  constructor(
    public UnifiedLoginAppId?: string,
    public AppName?: string,
    public AppKey?: string,
    public Description?: string,
    public TermsOfUse?: string,
    public PrivacyPolicy?: string,
    public Image?: string,
    public Url?: string,
  ) { }
}

@Injectable({
  providedIn: "root",
})
export class ApplicationAdapter implements Adapter<Application> {
  adapt (item: any): Application {
    if (!item) {
      return null as any;
    }
    return new Application(
      item.ee_unifiedloginappid,
      item.ee_appname,
      item.ee_appkey,
      item.ee_description,
      item.ee_termsofuse,
      item.ee_privacypolicy,
      item.ee_image,
      item.ee_url,
    )
  }
}