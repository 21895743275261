import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const notifier = this.injector.get(NotificationService);
    const authService = this.injector.get(MsalService);
    let correlationId = this.getCorrelationId(authService);

    let message: string = '';
    if (error instanceof HttpErrorResponse) {
      // server error
      message = error.message;
      if (correlationId !== undefined) {
        message = `CorrelationId: ${correlationId} Message: ` + message;
      }
      notifier.openServerErrorDialog(message);
    } else {
      // client error
      message = error.message ? error.message : error.toString();
      if (correlationId !== undefined) {
        message = `CorrelationId: ${correlationId} Message: ` + message;
      }
      notifier.showClientError(message);
    }
    // log every error to the console
    console.error(message);
    // throw the error to propagate it to the caller
    return throwError(message);
  }

  private getCorrelationId(authService: MsalService): string | unknown {
    return authService?.instance?.getActiveAccount()?.idTokenClaims?.['correlationId'];
  }
}