import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { Router} from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-login-failed',
  standalone: true,
  imports: [MatCardModule, MatIconModule],
  templateUrl: './login-failed.component.html',
  styleUrl: './login-failed.component.scss'
})
export class LoginFailedComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) { }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length > 0) {
          this.router.navigate(['/']);  
        }
      });
  }
}
