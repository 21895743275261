import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './core/pages/home/home.component';
import { ApplicationsComponent } from './features/application-access/pages/applications/applications.component';
import { LoginFailedComponent } from './core/pages/login-failed/login-failed.component';
import { UserProfileComponent } from './features/user-profile/pages/user-profile/user-profile.component';

export const routes: Routes = [
    {
        path: 'applications',
        component: ApplicationsComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'user-profile',
        component: UserProfileComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'login-failed',
        component: LoginFailedComponent
    }
];
