import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/core/services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class UserRightService {

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) { }

  fetchUserRights(skipLoader = false): Observable<any[]> {
    if (!skipLoader) {
      this.loadingService.loadingOn();
    }
    return this.http.get<any[]>(environment.crmConnectorConfig.uri + "/api/GetUserRights")
      .pipe(
        finalize(() => {
          if (!skipLoader) {
            this.loadingService.loadingOff();
          }
        })
      );
  }

  requestAccess(appKey: string | undefined): Observable<any[]> {
    this.loadingService.loadingOn();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { appkey: appKey };
    return this.http.post<any>(environment.crmConnectorConfig.uri + "/api/RequestUserAccessToApplication", body, { headers })
      .pipe(
        finalize(() => this.loadingService.loadingOff())
      );
  }
}
