import { Injectable } from '@angular/core'
import { Adapter } from 'src/app/core/interfaces/adapter.interface';

export class UserProfile {
  constructor(
    public Group?: number,
    public EMail?: string,
    public Title?: string,
    public FirstName?: string,
    public LastName?: string,
    public Company?: string,
    public StreetAddress?: string,
    public PostalCode?: string,
    public City?: string,
    public Country?: string,
    public PreferredLanguage?: number,
    public TelephoneNumber?: string,
    public GenderCode?: number,
  ) { }
}

@Injectable({
  providedIn: "root",
})
export class UserProfileAdapter implements Adapter<UserProfile> {
  adapt (item: any): UserProfile {
    if (!item) {
      return null as any;
    }
    return new UserProfile(
      item.Group,
      item.EMail,
      item.Title,
      item.FirstName,
      item.LastName,
      item.Company,
      item.StreetAddress,
      item.PostalCode,
      item.City,
      item.Country,
      item.PreferredLanguage,
      item.TelephoneNumber,
      item.GenderCode,
    )
  }
}