import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MsalService } from '@azure/msal-angular';
import { UserProfileService } from '../../services/user-profile.service';
import { UserProfileAdapter } from '../../models/user-profile.model';
import { Countries } from '../../models/country.model';

interface ValueViewValue {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, MatRadioModule],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnInit {
  languages: ValueViewValue[] = [
    {value: 1, viewValue: 'German'},
    {value: 2, viewValue: 'English'},
    {value: 3, viewValue: 'Spanish'},
    {value: 4, viewValue: 'French'},
    {value: 5, viewValue: 'Italian'},
    {value: 6, viewValue: 'Dutch'},
    {value: 7, viewValue: 'Polish'},
    {value: 8, viewValue: 'Portuguese'},
    {value: 9, viewValue: 'Russian'},
    {value: 10, viewValue: 'Chinese'},
  ];

  groups: ValueViewValue[] = [
    {value: 1, viewValue: 'Physicians'},
    {value: 2, viewValue: 'Scrub nurse'},
    {value: 3, viewValue: 'Hospital technician'},
    {value: 4, viewValue: 'Purchasing managers'},
    {value: 5, viewValue: 'Erbe specialized contract dealers'},
    {value: 7, viewValue: 'Specialized dealers'},
    {value: 9, viewValue: 'Sterile Processing Department'},
  ];

  genders: ValueViewValue[] = [
    {value: 2, viewValue: 'Female'},
    {value: 1, viewValue: 'Male'},
    {value: 3, viewValue: 'Neutral'},
  ];

  countries = Countries;

  profileForm: FormGroup;

  isXSmall = false;
  isSmall = false;

  validationPattern = '^[a-zA-Z0-9À-ž +_./()&!"\'-]*$';

  constructor(
    private responsive: BreakpointObserver,
    private formBuilder: FormBuilder,
    private userProfileService: UserProfileService,
    private userProfileAdapter: UserProfileAdapter,
    private authService: MsalService,
  ) {
    this.profileForm = this.formBuilder.group ({
      GenderCode: [null],
      FirstName: [null, [Validators.required, Validators.maxLength(50), Validators.pattern(this.validationPattern)]],
      LastName: [null, [Validators.required, Validators.maxLength(50), Validators.pattern(this.validationPattern)]],
      Title: [null, [Validators.maxLength(50), Validators.pattern(this.validationPattern)]],
      Company: [null, [Validators.required, Validators.maxLength(50), Validators.pattern(this.validationPattern)]],
      StreetAddress: [null, [Validators.required, Validators.maxLength(250), Validators.pattern(this.validationPattern)]],
      PostalCode: [null, [Validators.required, Validators.maxLength(20), Validators.pattern(this.validationPattern)]],
      City: [null, [Validators.required, Validators.maxLength(80), Validators.pattern(this.validationPattern)]],
      Country: [null, [Validators.required]],
      PreferredLanguage: [null],
      TelephoneNumber: [null, [Validators.required, Validators.maxLength(50), Validators.pattern(this.validationPattern)]],
      Group: [{value: null, disabled: true}, [Validators.required]],
      });
   }

  ngOnInit(): void {
    this.responsive.observe([
        Breakpoints.XSmall
      ])
      .subscribe(result => {
        this.isXSmall = false;

        if (result.matches) {
          this.isXSmall = true;
        }
      });

      this.responsive.observe([
        Breakpoints.Small
      ])
      .subscribe(result => {
        this.isSmall = false;

        if (result.matches) {
          this.isSmall = true;
        }
      });

    this.fetchUserProfile();
  }

  fetchUserProfile() {
    this.userProfileService.fetchUserProfile()
        .subscribe({
          next: (result: any) => {
            this.profileForm.patchValue(this.userProfileAdapter.adapt(result));
          },
          error: (error) => {
              console.error(error);
          }
        });
  }

  saveProfile() {
    var changedValues = this.getChanges();
    this.userProfileService.updateUserProfile(changedValues)
    .subscribe({
      complete: () => {
        window.location.reload();
      },
      error: (error) => {
          console.error(error);
      }
    });
  }

  isExtrenalUser(): boolean {
    if (this.authService.instance.getActiveAccount()?.idTokenClaims?.idp === undefined) {
      return true;
    } else {
      return false;
    }
  }

  private getChanges(): any {
    const updatedValues: any = {};
    this.getUpdates(this.profileForm, updatedValues);
    return updatedValues;
  }

  private getUpdates(
    formItem: FormGroup | FormArray | FormControl,
    updatedValues: any,
    name?: string
  ) {
    if (formItem instanceof FormControl) {
      if (name && formItem.dirty) {   
        updatedValues[name] = formItem.value;
      }
    } else {
      for (const formControlName in formItem.controls) {
        if (formItem.controls.hasOwnProperty(formControlName)) {
          const formControl = formItem.get(formControlName);

          if (formControl instanceof FormControl) {
            this.getUpdates(formControl, updatedValues, formControlName);
          } else if (
            formControl instanceof FormArray &&
            formControl.dirty &&
            formControl.controls.length > 0
          ) {
            updatedValues[formControlName] = [];
            this.getUpdates(formControl, updatedValues[formControlName]);
          } else if (formControl instanceof FormGroup && formControl.dirty) {
            updatedValues[formControlName] = {};
            this.getUpdates(formControl, updatedValues[formControlName]);
          }
        }
      }
    }
  }
}
