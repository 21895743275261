import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { UserRightService } from '../../services/user-right.service';
import { UserRight, UserRightAdapter } from '../../models/user-right.model';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { UserRightComponent } from '../../components/user-right/user-right.component';
import { environment } from 'src/environments/environment';
//import { UserProfileService } from 'src/app/features/user-profile/services/user-profile.service';

import { MsalService, MsalModule} from '@azure/msal-angular';

@Component({
  selector: 'app-applications',
  standalone: true,
  imports: [CommonModule, MatGridListModule, MatCardModule, MatIconModule, UserRightComponent, MsalModule],
  templateUrl: './applications.component.html',
  styleUrl: './applications.component.scss'
})
export class ApplicationsComponent implements OnInit, OnDestroy {
  userRights: BehaviorSubject<UserRight[] | null> = new BehaviorSubject<UserRight[] | null>(null)
  accessibleUserRights: Observable<UserRight[] | undefined> = new Observable<UserRight[] | undefined>(undefined)
  availableUserRights: Observable<UserRight[] | undefined> = new Observable<UserRight[] | undefined>(undefined)

  isXSmall = false;
  isSmall = false;

  public userWelcomeMessage ="";

  subscription !: Subscription;

  constructor(
    private responsive: BreakpointObserver,
    private userRightService: UserRightService,
    private rightAdapter: UserRightAdapter,
    //private userProfileService: UserProfileService,
    private authService: MsalService
  ) {}

  ngOnInit(): void {
    this.responsive.observe([
        Breakpoints.XSmall
      ])
      .subscribe(result => {
        this.isXSmall = false;

        if (result.matches) {
          this.isXSmall = true;
        }
      });

      this.responsive.observe([
        Breakpoints.Small
      ])
      .subscribe(result => {
        this.isSmall = false;

        if (result.matches) {
          this.isSmall = true;
        }
      });

    this.subscription = timer(10000, 60000)
      .subscribe(() => this.fetchApplicationsData(true) );

    this.getUserWelcomeMessage();

    this.fetchApplicationsData();

  }

  private fetchApplicationsData(skipLoader = false) {
    this.fetchUserRights(skipLoader);
    this.accessibleUserRights = this.getAccessibleUserRights();
    this.availableUserRights = this.getAvailableUserRights();
  }

  private fetchUserRights(skipLoader = false) {
    this.userRightService.fetchUserRights(skipLoader)
        .pipe(
          map((data: any[]) => data.map((item: any) => this.rightAdapter.adapt(item))),
          tap((data) => this.userRights.next(data)),
        )
        .subscribe({
          error: (error) => {
              console.error(error);
          }
        });
  }

  private getAccessibleUserRights(): Observable<UserRight[] | undefined> {
    return this.userRights.pipe(
      map(userRights => userRights?.filter( userRight => userRight.CrmStatusCode === 1 && userRight.Application?.AppKey?.toLowerCase() !== environment.msalConfig.auth.clientId.toLowerCase())),
      tap(userRights => userRights?.sort((a,b) => a.Application!.AppName!.localeCompare(b.Application!.AppName!)))
    );
  }

  private getAvailableUserRights(): Observable<UserRight[] | undefined> {
    return this.userRights.pipe(
      map(userRights => userRights?.filter( userRight => userRight.CrmStatusCode !== 1)),
      tap(userRights => userRights?.sort((a,b) => a.CrmStatusCode! - b.CrmStatusCode! || a.Application!.AppName!.localeCompare(b.Application!.AppName!)))
    );
  }

   //getUserWelcomeMessage() {

    //this.userProfileService.fetchUserProfile().subscribe({
      //next: (result: any) => {
        //this.userWelcomeMessage = result.FirstName == undefined ? "" : result.FirstName + " @ Erbe Connect";        
      //},
      //error: (error) => {
          //console.error(error);
      //}
    //});
      
  //}

  getUserWelcomeMessage() {
    this.userWelcomeMessage = this.authService.instance.getActiveAccount()?.idTokenClaims?.['given_name'] + " @ Erbe Connect";  
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
