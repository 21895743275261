<div class="row">
  <div class="row-container" [ngClass]="{'mobile': isXSmall}">
    <h1 class="row-container-header" [ngClass]="{'mobile': isXSmall}">{{ this.userWelcomeMessage }}</h1>
    <h2 class="row-container-item">Your accessible applications and services:</h2>
    <mat-grid-list *ngIf="(accessibleUserRights | async) !== undefined && (accessibleUserRights | async)!.length > 0" [cols]="isXSmall ? '3' : (isSmall ? '6' : '9')" [gutterSize]="isXSmall ? '20px' : (isSmall ? '30px' : '40px')" rowHeight="220px" class="row-container-item">
      <mat-grid-tile *ngFor="let userRight of accessibleUserRights | async" colspan="3">
        <app-user-right [userRight]="userRight" class="user-right"></app-user-right>
      </mat-grid-tile>
    </mat-grid-list>
    <h3 *ngIf="(accessibleUserRights | async) !== undefined && (accessibleUserRights | async)!.length === 0" class="row-container-item">It seems that you don't have access to the applications and services yet. If access is already requested, the approval process is underway. We will notify you by email once we granted access to you.</h3>
  </div>
</div>
<div class="row2">
  <div class="row-container" [ngClass]="{'mobile': isXSmall}">
    <h2 class="row-container-item">There is more to experience:</h2>
    <mat-grid-list *ngIf="(availableUserRights | async) !== undefined && (availableUserRights | async)!.length > 0" [cols]="isXSmall ? '3' : (isSmall ? '6' : '9')" [gutterSize]="isXSmall ? '20px' : (isSmall ? '30px' : '40px')" rowHeight="220px" class="row-container-item">
      <mat-grid-tile class="mat-grid-tile" *ngFor="let userRight of availableUserRights | async" [colspan]="3">
        <app-user-right [userRight]="userRight" class="user-right"></app-user-right>
      </mat-grid-tile>
    </mat-grid-list>
    <h3 *ngIf="(availableUserRights | async) !== undefined && (availableUserRights | async)!.length === 0" class="row-container-item">At the moment, we don't have any additional applications or services to offer.</h3>
  </div>
</div>
