import { Injectable } from '@angular/core'
import { Adapter } from 'src/app/core/interfaces/adapter.interface';
import { Application, ApplicationAdapter } from './application.model';

export class UserRight {
  constructor(
    public UnifiedLoginRightId?: string,
    public Language?: number,
    public CrmStatusCode?: number,
    public Application?: Application
  ) { }
}

@Injectable({
  providedIn: "root",
})
export class UserRightAdapter implements Adapter<UserRight> {

  constructor(
    private applicationAdapter: ApplicationAdapter
  ) { }

  adapt (item: any): UserRight {
    if (!item) {
      return null as any;
    }
    return new UserRight(
      item.ee_unifiedloginrightid,
      item.ee_language,
      item.statuscode,
      this.applicationAdapter.adapt(item.ee_unifiedloginappid)
    )
  }
}