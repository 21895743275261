import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandlerService } from '../services/global-error-handler.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private globalErrorHandlerService: GlobalErrorHandlerService) {}

  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // return throwError(error);
        return this.globalErrorHandlerService.handleError(error);
      })
    );
  }
}