@if(loading$ | async) {

  <div class="spinner-container">
  @if(customLoadingIndicator) {

    <ng-container *ngTemplateOutlet="customLoadingIndicator" />
  
  } @else {
  
    <mat-spinner />
  
  }
  </div>
    
}
