@if(!loginDisplay) {

  <div class="container">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
    <app-loading-indicator [detectRouteTransitions]="true" />
  </div>

} @else {
  
  <mat-toolbar class="toolbar">
    <mat-toolbar-row class="toolbar-row">
      <button mat-icon-button (click)="sidenav.toggle()" onclick="this.blur()">
        <mat-icon class="menu-icon">menu</mat-icon>
      </button>

      <a class="logo-button" (click)="goToErbe()">
        <img alt="ErbeLogo" class="icon" src="assets/Erbe_Logo_white.svg"/>
      </a>

      <a class="title" href="/">{{ title }}</a>

      <div class="toolbar-spacer"></div>

      <button mat-button class="menu-button" (click)="login()" *ngIf="!loginDisplay">
        Sign in/up <mat-icon class="menu-button-icon">account_circle</mat-icon>
      </button>

      <button mat-button class="menu-button" matTooltip="User menu for {{ getCurrentUser() }}" [matMenuTriggerFor]="userMenu" *ngIf="loginDisplay">
        <div *ngIf="!isMobile">{{ getCurrentUser() }}</div><mat-icon class="menu-button-icon">person</mat-icon>
      </button>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="logout()">
          Sign out <mat-icon>logout</mat-icon>
        </button>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container>
    <mat-sidenav #sidenav over class="sidenav">
      <mat-list>
        <mat-nav-list>
          <mat-list-item *ngIf="loginDisplay">
            <span class="menu-item">
              Logged in user: {{ getCurrentUser() }}
            </span>
          </mat-list-item>
          <a mat-list-item [routerLink]="['applications']" (click)="sidenav.toggle()">
            <span class="menu-item">
              <mat-icon mat-list-icon>apps</mat-icon>
              <span>Applications</span>
            </span>
          </a>
          <a mat-list-item *ngIf="isExtrenalUser()" [routerLink]="['user-profile']" (click)="sidenav.toggle()">
            <span class="menu-item">
              <mat-icon mat-list-icon>person</mat-icon>
              <span>User profile</span>
            </span>
          </a>
        </mat-nav-list>
      </mat-list>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <div class="container">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
        <app-loading-indicator [detectRouteTransitions]="true" />
      </div>
      <mat-toolbar color="accent">
        <mat-toolbar-row class="toolbar-row">
          <span class="footer-font-size">{{ footer }}</span>
          <div class="toolbar-spacer"></div>
          <a class="footer-link" href="{{ contactMailTo }}" target="_blank">Questions? Contact us</a>
          <a class="footer-link" href="{{ getTermsOfUse() }}" target="_blank">Terms of use</a>
          <a class="footer-link" href="{{ getPrivacyPolicy() }}" target="_blank">Privacy policy</a>
        </mat-toolbar-row>
      </mat-toolbar>
    </mat-sidenav-content>
  </mat-sidenav-container>

}