import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule, MatSidenav } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { RouterModule, RouterOutlet } from '@angular/router';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoadingIndicatorComponent } from './core/components/loading-indicator/loading-indicator.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterOutlet, MatSidenavModule, MatListModule, MatToolbarModule, MatButtonModule, MatMenuModule, MatIconModule, MatTooltipModule, LoadingIndicatorComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  title = ''; //title = 'Connect';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  isMobile = false;
  footer = '© ' + (new Date()).getFullYear() + ' Erbe Elektromedizin GmbH All rights reserved.';
  contactMailTo = "mailto:support@erbe-med.com?Subject=Contact";

  constructor(
    @Inject( MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private responsive: BreakpointObserver,
  ) {
  }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();

    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.responsive.observe([
        Breakpoints.XSmall
      ])
      .subscribe(result => {
        this.isMobile = false;
        this.footer = '© 2024 Erbe Elektromedizin GmbH All rights reserved.';

        if (result.matches) {
          this.isMobile = true;
          this.footer = '© ' + (new Date()).getFullYear();
        }
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(){
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect();
  }

  getCurrentUser() {
    this.authService.instance.getActiveAccount()?.username
    return this.authService.instance.getActiveAccount()?.name;
  }

  goToErbe() {

    if (environment.production){
      (window as any).open("https://connect.erbe-med.com/", "_self");
    }else{
      (window as any).open("https://test.connect.erbe-med.com/", "_self");
    }
    //(window as any).open("https://erbe-med.com", "_blank");
    
  }

  getTermsOfUse() {
    return environment.termsOfUse;
  }

  getPrivacyPolicy() {
    return environment.privacyPolicy;
  }

  toggleMenu() {
    this.sidenav.open();
  }

  isExtrenalUser(): boolean {
    if (this.authService.instance.getActiveAccount()?.idTokenClaims?.idp === undefined) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
