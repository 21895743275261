export interface Country {
  id: string;
  name: string;
}

export const Countries: Country[] = [
  { name: "Afghanistan", id: "AF" },
  { name: "Albania", id: "AL" },
  { name: "Algeria", id: "DZ" },
  { name: "American Samoa", id: "AS" },
  { name: "Andorra", id: "AD" },
  { name: "Angola", id: "AO" },
  { name: "Anguilla", id: "AI" },
  { name: "Antigua and Barbuda", id: "AG" },
  { name: "Argentina", id: "AR" },
  { name: "Armenia", id: "AM" },
  { name: "Aruba", id: "AW" },
  { name: "Australia", id: "AU" },
  { name: "Austria", id: "AT" },
  { name: "Azerbaijan", id: "AZ" },
  { name: "Bahamas", id: "BS" },
  { name: "Bahrain", id: "BH" },
  { name: "Barbados", id: "BB" },
  { name: "Belarus", id: "BY" },
  { name: "Belgium", id: "BE" },
  { name: "Belize", id: "BZ" },
  { name: "Benin", id: "BJ" },
  { name: "Bermuda", id: "BM" },
  { name: "Bhutan", id: "BT" },
  { name: "Bolivia", id: "BO" },
  { name: "Bosnia and Herzegovina", id: "BA" },
  { name: "Botswana", id: "BW" },
  { name: "Brazil", id: "BR" },
  { name: "British Virgin Islands", id: "VG" },
  { name: "Brunei Darussalam", id: "BN" },
  { name: "Bulgaria", id: "BG" },
  { name: "Burkina Faso", id: "BF" },
  { name: "Burundi", id: "BI" },
  { name: "Cambodia", id: "KH" },
  { name: "Cameroon", id: "CM" },
  { name: "Canada", id: "CA" },
  { name: "Cape Verde", id: "CV" },
  { name: "Cayman Islands", id: "KY" },
  { name: "Central African Republic", id: "CF" },
  { name: "Chad", id: "TD" },
  { name: "Chile", id: "CL" },
  { name: "Cocos (Keeling) Islands", id: "CC" },
  { name: "Colombia", id: "CO" },
  { name: "Comoros", id: "KM" },
  { name: "Cook Islands", id: "CK" },
  { name: "Costa Rica", id: "CR" },
  { name: "Croatia", id: "HR" },
  { name: "Cuba", id: "CU" },
  { name: "Curacao", id: "CW" },
  { name: "Cyprus", id: "CY" },
  { name: "Czech Republic", id: "CZ" },
  { name: "Dem. People`s Rep. of Korea", id: "KP" },
  { name: "Democratic Republic of Congo", id: "CD" },
  { name: "Denmark", id: "DK" },
  { name: "Djibouti", id: "DJ" },
  { name: "Dominica", id: "DM" },
  { name: "Dominican Republic", id: "DO" },
  { name: "Ecuador", id: "EC" },
  { name: "Egypt", id: "EG" },
  { name: "El Salvador", id: "SV" },
  { name: "Equatorial Guinea", id: "GQ" },
  { name: "Eritrea", id: "ER" },
  { name: "Estonia", id: "EE" },
  { name: "Eswatini", id: "SZ" },
  { name: "Ethiopia", id: "ET" },
  { name: "European Union", id: "EU" },
  { name: "Falkland Islands", id: "FK" },
  { name: "Faroe Islands", id: "FO" },
  { name: "Fed. of Saint Kitts and Nevis", id: "KN" },
  { name: "Federated States of Micronesia", id: "FM" },
  { name: "Finland", id: "FI" },{ name: "France", id: "FR" },
  { name: "French Polynesia", id: "PF" },
  { name: "French Southern Territory", id: "TF" },
  { name: "Gabon", id: "GA" },
  { name: "Gambia", id: "GM" },
  { name: "Georgia", id: "GE" },
  { name: "Germany", id: "DE" },
  { name: "Ghana", id: "GH" },
  { name: "Gibraltar", id: "GI" },
  { name: "Greece", id: "GR" },
  { name: "Greenland", id: "GL" },
  { name: "Grenada", id: "GD" },
  { name: "Guam", id: "GU" },
  { name: "Guatemala", id: "GT" },
  { name: "Guinea", id: "GN" },
  { name: "Guinea-Bissau", id: "GW" },
  { name: "Guyana", id: "GY" },
  { name: "Haiti", id: "HT" },
  { name: "Honduras", id: "HN" },
  { name: "Hong Kong", id: "HK" },
  { name: "Hungary", id: "HU" },
  { name: "Iceland", id: "IS" },
  { name: "India", id: "IN" },
  { name: "Iran", id: "IR" },
  { name: "Iraq", id: "IQ" },
  { name: "Irish Republic", id: "IE" },
  { name: "Islamic Republic of Pakistan", id: "PK" },
  { name: "Israel", id: "IL" },
  { name: "Italy", id: "IT" },
  { name: "Ivory Coast", id: "CI" },
  { name: "Jamaica", id: "JM" },
  { name: "Japan", id: "JP" },
  { name: "Jordan", id: "JO" },
  { name: "Kazakhstan", id: "KZ" },
  { name: "Kenya", id: "KE" },
  { name: "Kingdom of Thailand", id: "TH" },
  { name: "Kingdom of Tonga", id: "TO" },
  { name: "Kosovo", id: "XK" },
  { name: "Kuwait", id: "KW" },
  { name: "Kyrgyzstan", id: "KG" },
  { name: "Laos", id: "LA" },
  { name: "Latvia", id: "LV" },
  { name: "Lebanon", id: "LB" },
  { name: "Lesotho", id: "LS" },
  { name: "Liberia", id: "LR" },
  { name: "Libya", id: "LY" },
  { name: "Liechtenstein", id: "LI" },
  { name: "Lithuania", id: "LT" },
  { name: "Luxembourg", id: "LU" },
  { name: "Macao", id: "MO" },
  { name: "Madagascar", id: "MG" },
  { name: "Malawi", id: "MW" },
  { name: "Malaysia", id: "MY" },
  { name: "Maldives", id: "MV" },
  { name: "Mali", id: "ML" },
  { name: "Malta", id: "MT" },
  { name: "Mauritania", id: "MR" },
  { name: "Mauritius", id: "MU" },
  { name: "Mayotte", id: "YT" },
  { name: "Mexico", id: "MX" },
  { name: "Moldova", id: "MD" },
  { name: "Monaco", id: "MC" },
  { name: "Mongolia", id: "MN" },
  { name: "Montenegro", id: "ME" },
  { name: "Montserrat", id: "MS" },
  { name: "Morocco", id: "MA" },
  { name: "Mozambique", id: "MZ" },
  { name: "Namibia", id: "NA" },
  { name: "Nauru", id: "NR" },
  { name: "Nepal", id: "NP" },
  { name: "Netherlands", id: "NL" },
  { name: "Netherlands Antilles", id: "AN" },
  { name: "New Caledonia", id: "NC" },
  { name: "New Zealand", id: "NZ" },
  { name: "Nicaragua", id: "NI" },
  { name: "Niger", id: "NE" },
  { name: "Nigeria", id: "NG" },
  { name: "Niue", id: "NU" },
  { name: "Norfolk Island", id: "NF" },
  { name: "Norway", id: "NO" },
  { name: "Palestinian Territory", id: "PS" },
  { name: "Panama", id: "PA" },
  { name: "Papua New Guinea", id: "PG" },
  { name: "Paraguay", id: "PY" },
  { name: "People`s Republic of China", id: "CN" },
  { name: "Peoples Rep. of Bangladesh", id: "BD" },
  { name: "Peru", id: "PE" },
  { name: "Philippines", id: "PH" },
  { name: "Pitcairn Islands", id: "PN" },
  { name: "Poland", id: "PL" },
  { name: "Portugal", id: "PT" },
  { name: "Republic Congo", id: "CG" },
  { name: "Republic of Fiji", id: "FJ" },
  { name: "Republic of Indonesia", id: "ID" },
  { name: "Republic of Kiribati", id: "KI" },
  { name: "Republic of Korea", id: "KR" },
  { name: "Republic of North Macedonia", id: "MK" },
  { name: "Republic of Singapore", id: "SG" },
  { name: "Republic of Tuvalu", id: "TV" },
  { name: "Republic of Yemen", id: "YE" },
  { name: "Romania", id: "RO" },
  { name: "Russian Federation", id: "RU" },
  { name: "Rwanda", id: "RW" },
  { name: "Saint Barthélémy", id: "BL" },
  { name: "Saint Martin", id: "MF" },
  { name: "Saint Vincent and the Grenadines", id: "VC" },
  { name: "Samoa", id: "WS" },
  { name: "San Marino", id: "SM" },
  { name: "Sao Tome and Principe", id: "ST" },
  { name: "Saudi Arabia", id: "SA" },
  { name: "Senegal", id: "SN" },
  { name: "Serbia", id: "RS" },
  { name: "Seychelles", id: "SC" },
  { name: "Sierra Leone", id: "SL" },
  { name: "Sint Maarten", id: "SX" },
  { name: "Slovakia", id: "SK" },
  { name: "Slovenia", id: "SI" },
  { name: "Somalia", id: "SO" },
  { name: "South Africa", id: "ZA" },
  { name: "South Sudan", id: "SS" },
  { name: "Spain", id: "ES" },
  { name: "Sri Lanka", id: "LK" },
  { name: "St. Lucia", id: "LC" },
  { name: "St. Pierre and Miquelon", id: "PM" },
  { name: "State of Qatar", id: "QA" },
  { name: "State of Solomon Islands", id: "SB" },
  { name: "Sudan", id: "SD" },
  { name: "Sultanate of Oman", id: "OM" },
  { name: "Suriname", id: "SR" },
  { name: "Sweden", id: "SE" },
  { name: "Switzerland", id: "CH" },
  { name: "Syrian Arab Republic", id: "SY" },
  { name: "Taiwan", id: "TW" },
  { name: "Tajikistan", id: "TJ" },
  { name: "Tanzania", id: "TZ" },
  { name: "Togo", id: "TG" },
  { name: "Trinidad and Tobago", id: "TT" },
  { name: "Tunisia", id: "TN" },
  { name: "Türkiye", id: "TR" },
  { name: "Turkmenistan", id: "TM" },
  { name: "Turks and Caicos Islands", id: "TC" },
  { name: "Uganda", id: "UG" },
  { name: "Ukraina", id: "UA" },
  { name: "Union of Myanmar", id: "MM" },
  { name: "United Arab Emirates (UAE)", id: "AE" },
  { name: "United Kingdom", id: "GB" },
  { name: "United States of America", id: "US" },
  { name: "Uruguay", id: "UY" },
  { name: "US Virgin Islands", id: "VI" },
  { name: "Uzbekistan", id: "UZ" },
  { name: "Vanuatu", id: "VU" },
  { name: "Vatican City", id: "VA" },
  { name: "Venezuela", id: "VE" },
  { name: "Vietnam", id: "VN" },
  { name: "Wallis and Futuna Islands", id: "WF" },
  { name: "Zambia", id: "ZM" },
  { name: "Zimbabwe", id: "ZW" },
]