import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/core/services/loading.service';
import { UserProfile } from '../models/user-profile.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) { }

  fetchUserProfile(): Observable<any[]> {
    this.loadingService.loadingOn();
    return this.http.get<any[]>(environment.crmConnectorConfig.uri + "/api/GetUserProfile")
      .pipe(
        finalize(() => this.loadingService.loadingOff())
      );
  }

  updateUserProfile(changes: any | undefined): Observable<any[]> {
    this.loadingService.loadingOn();
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(environment.crmConnectorConfig.uri + "/api/UpdateUserProfile", JSON.stringify(changes), { headers })
      .pipe(
        finalize(() => this.loadingService.loadingOff())
      );
  }
}
