import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { UserRight } from '../../models/user-right.model';
import { UserRightService } from '../../services/user-right.service';

export enum CrmStatusCode {
  Approved = 1,
  Denied = 2,
  ForApproval = 134240000,
  Revoked = 134240001,
  RequestableByUser = 134240002
}

const BGC_APPROVED = '#ffffff'; //'#9dc86d';
const BGC_FOR_APPROVAL = '#ffdfad' //'#ffc25d';
const BGC_REVOKED = '#c4c4c4' //'#7f7f7f';
const BGC_DENIED = '#c4c4c4' //'#7f7f7f';
const BGC_REQUESTABLE = '#a5d7d5';

@Component({
  selector: 'app-user-right',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatIconModule],
  templateUrl: './user-right.component.html',
  styleUrl: './user-right.component.scss'
})
export class UserRightComponent {
  @Input() userRight!: UserRight;
  isMobile = false;
  bgColor = 'lightblue';
  CrmStatusCode = CrmStatusCode;

  constructor(
    private responsive: BreakpointObserver,
    private userRightService: UserRightService,
  ) { }

  ngOnInit(): void {
    this.responsive.observe([
        Breakpoints.XSmall
      ])
      .subscribe(result => {
        this.isMobile = false;

        if (result.matches) {
          this.isMobile = true;
        }
      });

    if (this.userRight?.CrmStatusCode === CrmStatusCode.Approved) {
      this.bgColor = BGC_APPROVED;
    }

    if (this.userRight?.CrmStatusCode === CrmStatusCode.ForApproval) {
      this.bgColor = BGC_FOR_APPROVAL;
    }

    if (this.userRight?.CrmStatusCode === CrmStatusCode.RequestableByUser) {
      this.bgColor = BGC_REQUESTABLE;
    }

    if (this.userRight?.CrmStatusCode === CrmStatusCode.Denied) {
      this.bgColor = BGC_DENIED;
    }

    if (this.userRight?.CrmStatusCode === CrmStatusCode.Revoked) {
      this.bgColor = BGC_REVOKED;
    }
  }

  requestAccess() {
    this.userRightService.requestAccess(this.userRight.Application?.AppKey)
        .subscribe({
          complete: () => {
            window.location.reload();
          },
          error: (error) => {
              console.error(error);
          }
        });
  }

  getImageSrc(): string {
    if (this.userRight && this.userRight.Application?.Image !== undefined) {
      return "data:image/png;base64, " + this.userRight.Application.Image;
    } else {
      return "/assets/Support-App-VIO-3.jpg";
    }
  }

  goToApplication() {
    if (this.userRight && this.userRight?.CrmStatusCode === CrmStatusCode.Approved) {
      if (this.userRight && this.userRight.Application?.Url !== undefined) {
        (window as any).open(this.userRight.Application?.Url, "_blank");
      } else {
        (window as any).open("https://erbe-med.com", "_blank");
      }
    }
  }

  goToTermsOfUse() {
    (window as any).open(this.userRight.Application?.TermsOfUse, "_blank");
  }

  goToPrivacyPolicy() {
    (window as any).open(this.userRight.Application?.PrivacyPolicy, "_blank");
  }
}
